<template>
  <div>
    <user-profile-component-global
      sTextDetailHeader="Detalle del usuario"
      :sBackHref="sBackHref"
      :bWithPathOrNameRoute="true"
      :bAdminPermission="false"
      :bViewPermissionsTable="false"
      :itemsBreadcrumbs="itemsBreadcrumbs"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      sBackHref: "",
      itemsBreadcrumbs: [],
    };
  },
  beforeMount() {
    this.setNameBreadcrumbs();
  },
  methods: {
    setNameBreadcrumbs() {
      let aBreadcrumbs = [
        {
          text: "Cuentas financieras",
          disabled: false,
          to: {name:"Account"},
        },
        {
          text: "Detalle de cuenta financiera",
          link: true,
          exact: true,
          disabled: false,
          to: { name: "AccountDetail" },
          params: { id: this.$route.params.id },
        },
        {
          text: "Detalle de transacción",
          link: true,
          exact: true,
          disabled: false,
          to: { name: "TransationDetail" },
          params: { id: this.$route.params.idTransation },
        },
        {
          text: "Detalle del usuario",
          link: false,
          exact: false,
          disabled: true,
        },
      ];
      this.itemsBreadcrumbs = aBreadcrumbs;
      this.sBackHref = `/home/account-detail/${this.$route.params.id}/transation/${this.$route.params.idTransation}`;
    },
  },
};
</script>

<style scoped>
</style>